import request from './ConfigRequest';
export const pageApiConfigDto = (apiConfigQueryDto)=>{
    return request({
        url: 'apiConfig/pageApiConfigDto',
        params: {
            index:apiConfigQueryDto.index,
            size:apiConfigQueryDto.pageSize,
            businessNameLike:apiConfigQueryDto.businessNameLike
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveApiConfig = (ApiConfigDto)=>{
    return request({
        url: 'apiConfig/saveApiConfigDto',
        method: 'post',
        data: ApiConfigDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateApiConfigDto = (ApiConfigDto)=>{
    return request({
        url: 'apiConfig/updateApiConfigDto',
        method: 'put',
        data: ApiConfigDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchApiConfigDto = (apiConfigQueryVo)=>{
    return request({
        url: 'apiConfig/pageApiConfigDto',
        params: {
            index:apiConfigQueryVo.index,
            size:apiConfigQueryVo.pageSize,
            id:apiConfigQueryVo.id,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const deleteApiConfigDto = (apiConfigDto)=>{
    return request({
        url: 'apiConfig/deleteApiConfigDto',
        method: 'delete',
        data: apiConfigDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};