import React, { useState } from "react";
import { updateApiConfigDto } from "../../service/ApiConfigRequest";

import {TextField,
    Button, Box, Container, makeStyles,Paper,Grid,
} from '@material-ui/core';
 
import { styled } from '@mui/material/styles';

import BusinessIdSelect from "../common/BusinessIdSelect";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    margin:10,
  }));

const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,
        width:'100%'

    },
    hidden:{
        display:'none',
    }
});
export default function ApiConfigEditForm({handleEditClose, handleEditSubmit, editApiConfigDto}) {
    const classes = userStyles();

 
    console.log('editApiConfigDto==', editApiConfigDto.id);
    const [id, setId] = useState(editApiConfigDto.id);
    const [status, setStatus] = useState(editApiConfigDto.status);
    const [encryptionScheme, setEncryptionScheme] = useState(editApiConfigDto.encryptionScheme);
    const [isBased64Encoded, setIsBased64Encoded] = useState(editApiConfigDto.isBased64Encoded);
    const [isWhitelistArray, setIsWhitelistArray] = useState(editApiConfigDto.isWhitelistArray);

   
    const [legacyPartnerEmail, setLegacyPartnerEmail] = useState(editApiConfigDto.legacyPartnerEmail);
    const [legacyPartnerSecret, setLegacyPartnerSecret] = useState(editApiConfigDto.legacyPartnerSecret);
    const [defaultNotificationUrl, setDefaultNotificationUrl] = useState(editApiConfigDto.defaultNotificationUrl);
    const [defaultRedirectUrl, setDefaultRedirectUrl] = useState(editApiConfigDto.defaultRedirectUrl);
    const [sslCertificateBlob, setSslCertificateBlob] = useState(editApiConfigDto.sslCertificateBlob);
    const [isTestAccount, setIsTestAccount] = useState(editApiConfigDto.isTestAccount);
    const [failedAttemptThreshold, setFailedAttemptThreshold] = useState(editApiConfigDto.failedAttemptThreshold);
    const [rateLimitThreshold, setRateLimitThreshold] = useState(editApiConfigDto.rateLimitThreshold);


    const [secretKey, setSecretKey] = useState(editApiConfigDto.secretKey);
    const [accessToken, setAccessToken] = useState(editApiConfigDto.accessToken);
 

    const [partnerPublicKey,setPartnerPublicKey] = useState(editApiConfigDto.partnerPublicKey);
    const [apiSignFlag,setApiSignFlag] = useState(editApiConfigDto.apiSignFlag);
    const [signMethod,setSignMethod]= useState(editApiConfigDto.signMethod);
    const [apiMerchantType,setApiMerchantType] = useState(editApiConfigDto.apiMerchantType);
    const [masterMerchantName,setMasterMerchantName] = useState(editApiConfigDto.masterMerchantName);
    const [notificationResponseSign,setNotificationResponseSign] = useState(editApiConfigDto.notificationResponseSign);
    const [partnerNotificationSwitch,setPartnerNotificationSwitch] = useState(editApiConfigDto.partnerNotificationSwitch);
    const [businessId,setBusinessId] = useState(editApiConfigDto.businessId);
    const [partnerPublicKeyError,setPartnerPublicKeyError] = useState("");
    
    const handleSubmitData=(e) =>{
        e.preventDefault();
    



        const saveApiConfigDto = {
            'id': id,
            'secretKey': secretKey,
            'accessToken': accessToken,
            'partnerPublicKey': partnerPublicKey,
            'apiSignFlag': apiSignFlag,
            'signMethod': signMethod,
            'apiMerchantType': apiMerchantType,
            'masterMerchantName': masterMerchantName,
            'notificationResponseSign': notificationResponseSign,
            'partnerNotificationSwitch': partnerNotificationSwitch,
            'businessId': businessId, 
        };
        updateApiConfigDto(saveApiConfigDto);

        handleEditSubmit();
    };
    const setBusinessIdChange = (businessText) => {
        console.log("setBusinessIdChange===", businessText);
        if (businessText) {
          setBusinessId(businessText.id);
        }
    };

    const clearSelectBusinessId = ()=>{
        setBusinessId();
    }
    return (
        <div>


          <Box sx={{ width: '100%' }}>

        
                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>
                    <TextField id="id"   value={id} className={classes.hidden}/>

 
                    <Grid container spacing={2}>
                  <Grid item xs={6}> <Item elevation={0}  >  <BusinessIdSelect setBusinessIdChange={setBusinessIdChange} business={editApiConfigDto.businessName} clearSelectBusinessId={clearSelectBusinessId}/> </Item></Grid>
                   
                    <Grid item xs={6}>
                    <Item elevation={0}>  
                        <TextField id="accessToken" className={classes.field} label="Access Token" onChange={((e)=> setAccessToken(e.target.value))}  value={accessToken} variant="standard" /></Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}>
                         <TextField id="secretKey" className={classes.field} label="Secret Key"   onChange={((e)=> setSecretKey(e.target.value))} value={secretKey}  variant="standard" />
                    </Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}><TextField id="partnerPublicKey" value={partnerPublicKey}  className={classes.field} label="Partner public key" onChange={((e)=> setPartnerPublicKey(e.target.value))} variant="standard"/></Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}>
                        <TextField id="apiSignFlag" value={apiSignFlag}  className={classes.field} label="Api sign flag" onChange={((e)=> setApiSignFlag(e.target.value))} variant="standard"/>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}><TextField id="signMethod" value={signMethod}  className={classes.field} label="Sign method" onChange={((e)=> setSignMethod(e.target.value))} variant="standard"/></Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}>
                        <TextField id="apiMerchantType"  value={apiMerchantType}  className={classes.field} label="Api merchant type" onChange={((e)=> setApiMerchantType(e.target.value))} variant="standard"/>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}><TextField id="masterMerchantName" value={masterMerchantName}  className={classes.field} label="Master merchant name" onChange={((e)=> setMasterMerchantName(e.target.value))} variant="standard"/></Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}>
                        <TextField id="notificationResponseSign" value={notificationResponseSign}  className={classes.field} label="Notification response sign" onChange={((e)=> setNotificationResponseSign(e.target.value))} variant="standard"/>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}><TextField id="partnerNotificationSwitch" value={partnerNotificationSwitch}  className={classes.field} label="Partner notification switch" onChange={((e)=> setPartnerNotificationSwitch(e.target.value))} variant="standard"/></Item>
                    </Grid>
                    
                    <Grid item xs={6}>
                    <Item elevation={0}> <Button type="submit" variant="outlined" className="btn right">Submit</Button></Item>
                    </Grid>
                    
                    <Grid item xs={6}>
                    <Item elevation={0}>   <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button></Item>
                    </Grid>
                </Grid>
                    
                   

                </form>
            </Box>
        </div>
    );
}

