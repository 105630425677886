import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import TransactionEditForm from "./TransactionEditForm";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import TransactionRefundForm from "./TransactionRefundForm" ;

import {
  pageTransaction,
  searchTransaction,
  updateTransaction,
} from "../../store/transaction/TransactionSlice";

import {
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Grid,
  Box,
  makeStyles,
  Input,
  Checkbox,
  Stack,
} from "@material-ui/core";

import {
  pageTransactionDto,
  searchTransactionDto,
  selectTransactionDtoExport,
  exportPreciseTransactionXlsDtoExport,
  selectTransactionDtoExportManage
} from "../../service/TransactionRequest";



import { TransactionAddButtonControl } from "./style/TransactionStyle";
import StoreIdSelect from "../common/StoreIdSelect";
import BusinessIdSelect from "../common/BusinessIdSelect";
import { BigNumber } from "bignumber.js";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

export default function TransactionTable() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [data, setData] = useState([["Loading Data..."]]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(0);
  const [open, setOpen] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const [editTransactionDto, setEditTransactionDto] = useState({});

  const [refreshFlag, setRefreshFlag] = useState(false);

  const [searchName, setSearchName] = useState("");

  const [searchRefreshFlag, setSearchRefreshFlag] = useState(false);
  const [selectedDate, setSelectedDate] =  useState(new Date('2014-08-18T21:11:54'));

  const [store, setStore] = useState();
  const [storeId,setStoreId] = useState();
  const [business, setBusiness] = useState();
  const [businessId, setBusinessId] = useState();
  const [creationTimeStart,setCreationTimeStart] = useState();
  const [creationTimeEnd,setCreationTimeEnd] = useState();
  const [capturedTimeStart,setCapturedTimeStart] = useState();
  const [capturedTimeEnd,setCapturedTimeEnd] = useState();
  const [storeLabelText,setStoreLabelText] = useState("");
  const [tradeNumber,setTradeNumber] = useState();
  const [acquirerTransactionId,setAcquirerTransactionId] = useState();
  const [paymentStatus,setPaymentStatus] = useState();
  const [id,setId] = useState();
  const [externalTransactionId,setExternalTransactionId] = useState();
  const [clientTransactionId,setClientTransactionId] = useState();
  const [paymentLinkTransactionId,setPaymentLinkTransactionId] = useState();
  
  const [refundOpen, setRefundOpen] = useState(false);
  const [refundTransactionDto, setRefundTransactionDto] = useState({});

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const { control, handleSubmit,reset } = useForm({
    defaultValues: {
      transactionId: '',
      tradeNumber:'',
      acquirerTransactionId:'',
      externalTransactionId:'',
      clientTransactionId:'',
      paymentLinkTransactionId:'',
    }

    
  });

  const onSubmit = (data) =>{
 
    console.log(data);
    console.log("businessId==",businessId);
    console.log("storeId==",storeId);
    console.log("creationTimeStart==",creationTimeStart);
    console.log("creationTimeEnd==",creationTimeEnd);
    console.log("capturedTimeStart==",capturedTimeStart);
    console.log("capturedTimeEnd==",capturedTimeEnd);
    console.log("externalTransactionId==",externalTransactionId);
    console.log("clientTransactionId==",clientTransactionId);

    setId(data.transactionId);
    setAcquirerTransactionId(data.acquirerTransactionId); 
    setTradeNumber(data.tradeNumber);
    setPaymentStatus(data.paymentStatus.value); 
    setExternalTransactionId(data.externalTransactionId);
    setClientTransactionId(data.clientTransactionId);

    let  transactionQueryVo = {
      "index":1,
      "pageSize":10,
      "id":data.transactionId ? data.transactionId: null ,
      "storeId":storeId ? storeId.toString() :null,
      "businessId": businessId ?  businessId : null,
      "acquirerTransactionId":data.acquirerTransactionId ? data.acquirerTransactionId : null,
      "tradeNumber":data.tradeNumber ? data.tradeNumber : null,
      "paymentStatus":data.paymentStatus.value ? data.paymentStatus.value : null,
      "creationTimeStart":creationTimeStart ? creationTimeStart:  null,
      "creationTimeEnd":creationTimeEnd ?  creationTimeEnd: null,
      "capturedTimeStart":capturedTimeStart ?   capturedTimeStart: null,
      "capturedTimeEnd":capturedTimeEnd ? capturedTimeEnd: null,
      "externalTransactionId":data.externalTransactionId ? data.externalTransactionId: null,
      "clientTransactionId":data.clientTransactionId ? data.clientTransactionId : null,
      "paymentLinkTransactionId":data.paymentLinkTransactionId ? data.paymentLinkTransactionId : null,
    };
    initData(transactionQueryVo);
  } 

  const searchTransaction = () => {
    console.log("======searchTransaction", searchName);
    setSearchRefreshFlag(true);
  };

  const handleClose = () => {
    console.log("======handle close");
    setOpen(!open);
 
  };

  const handleRefundClose = () => {
    console.log("======handleRefundClose close");
    setRefundOpen(!refundOpen);
 
  };


  const handleEditSubmit = () => {
    console.log("======handle edit submit");
    setOpen(!open);
  

    setTimeout(() => {
      setRefreshFlag(true);
    }, 10);
  };

  const handleRefundSubmit = () => {
    console.log("======handle edit submit");
    setRefundOpen(!refundOpen);
  

    setTimeout(() => {
      setRefreshFlag(true);
    }, 10);
  };

  const changePage = (page, sortOrder) => {
    setIsLoading(true);
 
    console.log("===page", page);
    page = page + 1;
    
    let  transactionQueryVo = {
      "index":page,
      "pageSize":10,
      "id":id ? id: null ,
      storeId:storeId ? new BigNumber(storeId).toString() :null,
       
      "acquirerTransactionId":acquirerTransactionId ? acquirerTransactionId : null,
      "tradeNumber":tradeNumber ? tradeNumber : null,
      "paymentStatus":paymentStatus? paymentStatus : null,
      "creationTimeStart":creationTimeStart ? creationTimeStart:  null,
      "creationTimeEnd":creationTimeEnd ?  creationTimeEnd: null,
      "capturedTimeStart":capturedTimeStart ?   capturedTimeStart: null,
      "capturedTimeEnd":capturedTimeEnd ? capturedTimeEnd: null,
      "externalTransactionId":externalTransactionId ? externalTransactionId: null,
      "clientTransactionId":clientTransactionId ? clientTransactionId : null,
    };
    initData(transactionQueryVo);
  };

  const editTransactionData = (tableMeta) => {
  
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setOpen(!open);
    const editTransactionDto = data[row];
    setEditTransactionDto(editTransactionDto);
  };


  const refundTransactionData = (tableMeta) => {
  
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setRefundOpen(!refundOpen);
    const refundTransactionDto = data[row];
    setRefundTransactionDto(refundTransactionDto);
  };
 
  const creationTimeStartChange =(startTime) => {
    console.log("creationTimeStartChange==", startTime);
    startTime = startTime + " 00:00:00";
    setCreationTimeStart(startTime);
  }

  const creationTimeEndChange =(endTime) => {
    console.log("creationTimeEndChange==", endTime);
    endTime = endTime + " 00:00:00";
    setCreationTimeEnd(endTime);
  }

  const capturedTimeStartChange =(startTime) => {
    console.log("capturedTimeStartChange==", startTime);
    startTime = startTime + " 00:00:00";
    setCapturedTimeStart(startTime);
  }

  const capturedTimeEndChange =(endTime) => {
    console.log("capturedTimeEndChange===", endTime);
    endTime = endTime + " 00:00:00";
    setCapturedTimeEnd(endTime);
  }

  const setBusinessIdChange = (businessText) => {
    console.log("setBusinessIdChange===", businessText);
    if (businessText) {
      setBusinessId(businessText.id);
    }
  };

  const exportTransactionXls = async() => {

    console.log("exportTransactionXls");

    try{
      let  transactionQueryVo = {
        "index":1,
        "pageSize":10,
        "id":id ? id: null ,
        "storeId":storeId ? storeId.toString() :null,
        "businessId":businessId ? businessId.toString() : null,
        "acquirerTransactionId":acquirerTransactionId ? acquirerTransactionId.toString() : null,
        "tradeNumber":tradeNumber ? tradeNumber : null,
        "paymentStatus":paymentStatus ? paymentStatus : null,
        "creationTimeStart":creationTimeStart ? creationTimeStart:  null,
        "creationTimeEnd":creationTimeEnd ?  creationTimeEnd: null,
        "capturedTimeStart":capturedTimeStart ?   capturedTimeStart: null,
        "capturedTimeEnd":capturedTimeEnd ? capturedTimeEnd: null,
        "externalTransactionId":externalTransactionId ? externalTransactionId: null,
      "clientTransactionId":clientTransactionId ? clientTransactionId : null,
      };
      let transactionExportDto = await selectTransactionDtoExportManage(transactionQueryVo);

 
       // 2. Create blob link to download
       const url = window.URL.createObjectURL(new Blob([transactionExportDto]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', "transaction.xlsx");
       // 3. Append to html page
       document.body.appendChild(link);
       // 4. Force download
       link.click();
       // 5. Clean up and remove the link
       link.parentNode.removeChild(link);
            
    }catch(e){
      console.log("exportTransactionXls===error",e);
    }

  }

  const exportPreciseTransactionXls = async() => {

    console.log("exportPreciseTransactionXls");

    try{
      let  transactionQueryVo = {
        "index":1,
        "pageSize":10,
        "id":id ? id: null ,
        "storeId":storeId ? storeId.toString() :null,
        "businessId":businessId ? businessId.toString() : null,
        "acquirerTransactionId":acquirerTransactionId ? acquirerTransactionId.toString() : null,
        "tradeNumber":tradeNumber ? tradeNumber : null,
        "paymentStatus":paymentStatus ? paymentStatus : null,
        "creationTimeStart":creationTimeStart ? creationTimeStart:  null,
        "creationTimeEnd":creationTimeEnd ?  creationTimeEnd: null,
        "capturedTimeStart":capturedTimeStart ?   capturedTimeStart: null,
        "capturedTimeEnd":capturedTimeEnd ? capturedTimeEnd: null,
        "externalTransactionId":externalTransactionId ? externalTransactionId: null,
      "clientTransactionId":clientTransactionId ? clientTransactionId : null,
      "externalTransactionId":externalTransactionId ,
      "clientTransactionId":clientTransactionId,
      };
      let transactionExportDto = await exportPreciseTransactionXlsDtoExport(transactionQueryVo);

     
       // 2. Create blob link to download
       const url = window.URL.createObjectURL(new Blob([transactionExportDto]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', "transaction-precise.xlsx");
       // 3. Append to html page
       document.body.appendChild(link);
       // 4. Force download
       link.click();
       // 5. Clean up and remove the link
       link.parentNode.removeChild(link);
            
    }catch(e){
      console.log("exportPreciseTransactionXls===error",e);
    }

  }
  const options = {
    filterType: "dropdown",
    responsive: "vertical",
    serverSide: true,
    count: count,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    sortOrder: { sortOrder },
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    onTableChange: (action, tableState) => {
      
  
      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          this.sort(tableState.page, tableState.sortOrder);
          break;
        default:
          console.log("action not handled.");
      }
    },
  };

  const initData = async(transactionQueryVo) => {
    setIsLoading(true);
    try {
 
       
      let initResponseDto = await pageTransactionDto(transactionQueryVo);
      
      console.log("====data", initResponseDto.data);
      if (initResponseDto && initResponseDto.data) {
        setData(initResponseDto.data.records);
        setCount(initResponseDto.data.total);
      } 
      setIsLoading(false);

      setPage(transactionQueryVo.index);
       
    }catch(e) {
      console.log("initData==error", e);
    }
   
    setIsLoading(false);
  };

  const searchTransactionData = async (index, pageSize, searchName) => {
    setIsLoading(true);

    try{
      let transactionQueryVo = {
        "index":index, 
        "pageSize":pageSize, 
        "id":searchName
      }
      let responseDto  = await searchTransactionDto(transactionQueryVo);
      console.log("====data", responseDto.data);
      if (responseDto && responseDto.data) {
        setData(responseDto.data.records);
        setCount(responseDto.data.total);
      }

      setIsLoading(false);

      setPage(index);
    }catch(e){
      console.log("searchTransactionData==error==",e);
    }
    
    setIsLoading(false);

  };
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    let  transactionQueryVo = {
      "index":1,
      "pageSize":10,
    };
    initData(transactionQueryVo);
    setRefreshFlag(false);
  }, [refreshFlag]);

  const setStoreIdChange = (storeText) => {
    
    console.log("setStoreIdChange===", storeText);
    if (storeText) {
      setStoreId(storeText.id);
      
    }
  };


  const transactionReset = () => {
    reset();
    setBusinessId();
    setStoreId();
    setCreationTimeStart();
    setCreationTimeEnd();
    setCapturedTimeStart();
    setCapturedTimeEnd();
    setExternalTransactionId();
    setClientTransactionId();
    setPaymentLinkTransactionId();
   
  }


  const clearSelectStoreId =() => { 
    console.log("Clear storeId");
    setStoreId();
  }

  const clearSelectBusinessId = ()=>{
    console.log("Clear businessId");
    setBusinessId();
  }
  useEffect(() => {
    console.log("===searchTransactionData=");
    if (searchRefreshFlag) {
      console.log("searchName===", searchName);
    }

    if (!searchName) return;
    searchTransactionData(page, 10, searchName);

    setSearchRefreshFlag(false);
  }, [searchRefreshFlag]);

  const configurationData = {
    page: 0,
    count: 1,
    rowsPerPage: 10,
    sortOrder: {},
    data: [["Loading Data..."]],
    columns: [
      {
        name: "id",
        label: "Id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).

            if (value) {
              return new BigNumber(value).toString();
            }
            return value;
          },
        },
      },

      {
        name: "storeName",
        label: "Store Name",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
             
            return value;
          },
        },
      },
      {
        name: "buisnessName",
        label: "Company Name",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
             
            return value;
          },
        },
      },
      {
        name: "amount",
        label: "Amount",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.

            
            if (value) {
              return value ;
            }
            return value;
          },
        },
      },
      {
        name: "mdr",
        label: "ServiceCharge",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.

            
            if (value) {
              return value ;
            }
            return value;
          },
        },
      },{
        name: "netAmount",
        label: "NetAmount",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.

            
            if (value) {
              return value ;
            }
            return value;
          },
        },
      },{
        name: "serviceCharge",
        label: "MDR",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.

            
            if (value) {
              return value ;
            }
            return value;
          },
        },
      },
      {
        name: "paymentStatus",
        label: "Payment Status",
        options: {},
      },
      {
        name: "capturedTime",
        label: "Payment time",
        options: {},
      },{
        name: "refundStatus",
        label: "Refund status",
        options: {},
      },
      {
        name: "transactionSource",
        label: "Transaction Source",
        options: {},
      },
      {
        name: "acquirerTypeString",
        label: "Acquirer Type",
        options: {},
      },
      {
        name: "acquirerTransactionId",
        label: "Acquirer Transaction Id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            if (value) {
              return value.toString();
            }
            return value;
          },
        },
      },
      {
        name: "externalTransactionId",
        label: "External transaction id",
        options: {},
      },
      {
        name: "clientTransactionId",
        label: "Client transaction id",
        options: {},
      },
      
      {
        name: "creationTime",
        label: "Create Time",
        options: {},
      },
      {
        name: "paymentLinkTransactionId",
        label: "Payment link transaction id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).

            if (value) {
              return new BigNumber(value).toString();
            }
            return value;
          },
        },
      },
      {
        name: "lastUpdateTime",
        label: "Last update time",
        options: {},
      },
      {
        name: "belongFlag",
        label: "Belong flag",
        options: {},
      },  {
        name: "dcsPaymentMethod",
        label: "Payment Method",
        options: {},
      },
      {
        name: "cardBelong",
        label: "Card Belong",
        options: {},
      },
      {
        name: "cardIssuerCode",
        label: "Card Issuer Code",
        options: {},
      },
      {
        name: "cardPayWay",
        label: "Card Pay Way",
        options: {},
      },
      {
        name: "Action",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) => editTransactionData(tableMeta)}
                >
                  Edit
                </Button>

                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) => refundTransactionData(tableMeta)}
                >
                  Refund
                </Button>
              </Box>
            );
          },
        },
      },
    ],
  };

  return (
    <div>
        <form >
      <Grid container spacing={3}>
        
        <Grid item xs={4}>
          <Paper className={classes.paper}>Id:
            <Controller
            name="transactionId"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
         </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}> 
             <label>Payment Status</label>
          <Controller
            name="paymentStatus"
            control={control}
            defaultValue=""
            render={({ field }) => <Select 
              {...field} 
              options={[
                { value: "success", label: "success" },
                { value: "cancel", label: "cancel" },
                { value: "fail", label: "fail" }
              ]} 
            />
            }
          />
       
        </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}> 
        <TextField
        id="creationTimeStart"
        name="creationTimeStart"
        label="Create time start"
        control={control}
        type="date"
        defaultValue={creationTimeStart}
         
        sx={{ width: 220 }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => creationTimeStartChange(e.target.value)}
      />

   

      
      </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.paper}>
            
            <TextField
            id="creationTimeEnd"
            name="creationTimeEnd"
            label="Create time end"
            type="date"
            defaultValue={creationTimeEnd}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => creationTimeEndChange(e.target.value)}
            
          />
            </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>TradeNumber:
          <Controller
            name="tradeNumber"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>AcquirerTransactionId:<Controller
            name="acquirerTransactionId"
            control={control}
            render={({ field }) => <Input {...field} />}
            /></Paper>
        </Grid>
        <Grid item xs={4}>
              <Paper className={classes.paper}> <TextField
            id="capturedTimeStart"
            name="capturedTimeStart"
            label="Pay time start"
            control={control}
            type="date"
            defaultValue={capturedTimeStart}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => capturedTimeStartChange(e.target.value)}
          />
          </Paper>
        </Grid>
        <Grid item xs={4}>
            <Paper className={classes.paper}>
          <TextField
          id="capturedTimeEnd"
          name="capturedTimeEnd"
          label="Pay time end"
          control={control}
          type="date"
          defaultValue="{capturedTimeEnd}"
          sx={{ width: 220 }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => capturedTimeEndChange(e.target.value)}
        />
        </Paper>
      </Grid>
      <Grid item xs={4}>
          <Paper className={classes.paper}> 
            <StoreIdSelect 
                        setStoreIdChange={setStoreIdChange}
                        store = {store}
                        clearSelectStoreId={clearSelectStoreId} 
                        storeLabelText ={storeLabelText}/>
            </Paper>
        </Grid>
        <Grid item xs={4}>

        <Paper className={classes.paper}>External Transaction Id:<Controller
            name="externalTransactionId"
            control={control}
            render={({ field }) => <Input {...field} />}
            /></Paper>
        </Grid>
        <Grid item xs={4}>

      <Paper className={classes.paper}>Client Transaction Id:<Controller
          name="clientTransactionId"
          control={control}
          render={({ field }) => <Input {...field} />}
          /></Paper>
      </Grid>
      <Grid item xs={4}>

<Paper className={classes.paper}>Payment Link Transaction Id:<Controller
    name="paymentLinkTransactionId"
    control={control}
    render={({ field }) => <Input {...field} />}
    /></Paper>
</Grid>
  
        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={handleSubmit(onSubmit)} variant={"outlined"} color="primary">Search</Button></Paper>
        </Grid>
        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={() => transactionReset()} variant={"outlined"} color="primary">Reset</Button></Paper>
        </Grid>

        {/* <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={() => exportPreciseTransactionXls()} variant={"outlined"} color="primary">Export precise excel </Button></Paper>
        </Grid> */}
        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={() => exportTransactionXls()} variant={"outlined"} color="primary">Export Detail excel </Button></Paper>
        </Grid>

      </Grid>
      </form>
      {/* <TransactionAddButtonControl>
        <Button
          className="btn right"
          variant="outlined"
          onClick={searchTransaction}
          color="primary"
        >
          Search
        </Button>
      </TransactionAddButtonControl> */}

      <MUIDataTable
        title={
          <Typography variant="h6">
            Transaction list
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={data}
        columns={configurationData.columns}
        options={options}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "50%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Edit Transaction</DialogTitle>
        <DialogContent>
          <TransactionEditForm
            handleEditClose={handleClose}
            handleEditSubmit={handleEditSubmit}
            editTransactionDto={editTransactionDto}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={refundOpen}
        onClose={handleRefundClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "50%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Refund Transaction</DialogTitle>
        <DialogContent>
          <TransactionRefundForm
          handleRefundClose={handleRefundClose}
          handleRefundSubmit={handleRefundSubmit}
            refundTransactionDto={refundTransactionDto}
          />
        </DialogContent>
      </Dialog>

      
    </div>
  );
}
