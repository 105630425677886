import React, { useState } from "react";
import { saveApiConfig } from "../../service/ApiConfigRequest";
 
import { styled } from '@mui/material/styles';
import {TextField,
    Button, Box, Container, makeStyles,Paper,Grid,
} from '@material-ui/core';
import {
    ApiConfigFormBottonControl,
} from './style/ApiConfigStyle';
import Stack from '@mui/material/Stack';
import { use } from "react";
import BusinessIdSelect from "../common/BusinessIdSelect";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    margin:10,
  }));

const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,
        width:'100%'

    },
});
export default function ApiConfigForm({handleAddClose, handleSubmit}) {
    const classes = userStyles();

 

    const [secretKey, setSecretKey] = useState('');
    const [accessToken, setAccessToken] = useState('');
 

    const [partnerPublicKey,setPartnerPublicKey] = useState("");
    const [apiSignFlag,setApiSignFlag] = useState("");
    const [signMethod,setSignMethod]= useState("");
    const [apiMerchantType,setApiMerchantType] = useState("");
    const [masterMerchantName,setMasterMerchantName] = useState("");
    const [notificationResponseSign,setNotificationResponseSign] = useState("");
    const [partnerNotificationSwitch,setPartnerNotificationSwitch] = useState("");
    const [businessId,setBusinessId] = useState("");
    const [partnerPublicKeyError,setPartnerPublicKeyError] = useState("");
    const handleSubmitData=async (e) =>{
        e.preventDefault();
        
        if(!partnerPublicKey){
            setPartnerPublicKeyError("Public key not empty!!");
            return;
        }

        const saveApiConfigDto = {
            'businessId': businessId,
            'accessToken': accessToken,
            'secretKey': secretKey,
            'partnerPublicKey': partnerPublicKey,
            'apiSignFlag': apiSignFlag,
            'signMethod': signMethod,
            'apiMerchantType': apiMerchantType,
            'masterMerchantName': masterMerchantName,
            'notificationResponseSign': notificationResponseSign,
            'partnerNotificationSwitch': partnerNotificationSwitch,
          
        };
        await saveApiConfig(saveApiConfigDto);

        console.log('===save====');

        handleSubmit();
    };

    const setBusinessIdChange = (businessText) => {
        console.log("setBusinessIdChange===", businessText);
        if (businessText) {
          setBusinessId(businessText.id);
        }
    };

    
    return (
       

        <Box sx={{ width: '100%' }}>
            
              

                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    
                     
                     
                  <Grid container spacing={2}>
                  <Grid item xs={6}> <Item elevation={0}  >  <BusinessIdSelect setBusinessIdChange={setBusinessIdChange} /> </Item></Grid>
                   
                    <Grid item xs={6}>
                    <Item elevation={0}>  
                        <TextField id="accessToken" className={classes.field} label="Access Token" onChange={((e)=> setAccessToken(e.target.value))} variant="standard" /></Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}>
                         <TextField id="secretKey" className={classes.field} label="Secret Key"   onChange={((e)=> setSecretKey(e.target.value))} variant="standard" />
                    </Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}><TextField id="partnerPublicKey"  className={classes.field} label="Partner public key" onChange={((e)=> setPartnerPublicKey(e.target.value))} variant="standard"/></Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}>
                        <TextField id="apiSignFlag" className={classes.field} label="Api sign flag" onChange={((e)=> setApiSignFlag(e.target.value))} variant="standard"/>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}><TextField id="signMethod" className={classes.field} label="Sign method" onChange={((e)=> setSignMethod(e.target.value))} variant="standard"/></Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}>
                        <TextField id="apiMerchantType" className={classes.field} label="Api merchant type" onChange={((e)=> setApiMerchantType(e.target.value))} variant="standard"/>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}><TextField id="masterMerchantName" className={classes.field} label="Master merchant name" onChange={((e)=> setMasterMerchantName(e.target.value))} variant="standard"/></Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}>
                        <TextField id="notificationResponseSign" className={classes.field} label="Notification response sign" onChange={((e)=> setNotificationResponseSign(e.target.value))} variant="standard"/>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}><TextField id="partnerNotificationSwitch" className={classes.field} label="Partner notification switch" onChange={((e)=> setPartnerNotificationSwitch(e.target.value))} variant="standard"/></Item>
                    </Grid>
                    
                    <Grid item xs={6}>
                    <Item elevation={0}> <Button type="submit" variant="outlined"   >Submit</Button></Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item elevation={0}>  <Button variant ="outlined" onClick={handleAddClose}   >close</Button></Item>
                    </Grid>
                </Grid>

            
                    
               
    
                </form>
                </Box>
               
             
      
    );
}

