import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Box,
  Grid,
  makeStyles,
  Input,
  styled,
  DialogContentText,
} from "@material-ui/core";

import MUIDataTable from "mui-datatables";

import { BigNumber } from "bignumber.js";
import { useForm, Controller } from "react-hook-form";

import {
  pageBusinessDto,
  searchBusinessDto,
  importMerchantExcel,
  importAcquirerExcel,
  importStoreExcel,
} from "../../service/BusinessRequest";

import { BusinessAddButtonControl } from "./style/BusinessStyle";

import BusinessForm from "./BusinessForm";
import BusinessEditForm from "./BusinessEditForm";

import BankDetailIdSelect from "../common/BusinessIdSelect";
import {ERROR_CODE_SUCCESS} from "../../components/common/constant/ResponseErrorCodeConstant";

import Stack from '@mui/material/Stack';
import {deleteBusinessDto } from "../../service/BusinessRequest";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
export default function BusinessTable() {

  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([["Loading Data..."]]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(0);
  const [open, setOpen] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const [editBusinessDto, setEditBusinessDto] = useState({});

  const [refreshFlag, setRefreshFlag] = useState(false);

  const [searchName, setSearchName] = useState("");

  const [searchRefreshFlag, setSearchRefreshFlag] = useState(false);

  const [businessSelectId, setBusinessSelectId] = useState();

  const [id,setId] = useState();
  const [bankAccount,setBankAccount] = useState();
  const [phoneNumber,setPhoneNumber] = useState();
  const [address,setAddress] = useState();
  const [bankName,setBankName] = useState();
  const [businessName,setBusinessName] = useState();

  const [businessExcelFile,setBusinessExcelFile] = useState();
 
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [businessId,setBusinessId]=useState();
  const searchBusiness = () => {
    console.log("======searchBusinesss", searchName);
    setSearchRefreshFlag(true);
  };

  const addBusiness = () => {
    console.log("======handle add addBusiness");
    setAddOpen(!addOpen);
  };

  const handleClose = () => {
    console.log("======handle close");
    setOpen(!open);
  };

  const handleEditSubmit = () => {
    console.log("======handle edit submit");
    setOpen(!open);
 

    setTimeout(() => {
      setRefreshFlag(true);
    }, 10);
  };

  const handleSubmitBusiness = () => {
     
    console.log("==handleSubmit=");

    setAddOpen(!addOpen);

    setRefreshFlag(true);
  };

  const handleAddClose = () => {
    
    console.log("======handle add close");
    setAddOpen(!addOpen);
    setTimeout(() => {
      setRefreshFlag(true);
    }, 1000);
  };


  const handleDeleteClose = () => {
    console.log("======handle close");
    setDeleteOpen(!open);
  };

  const changePage = (page, sortOrder) => {
    setIsLoading(true);
     
    console.log("===page", page);
    page = page + 1;

    let changePageBusinessQueryVo = {
      index:page,
      pageSize:10,
      id:id,
      bankAccount:bankAccount,
      phoneNumber: phoneNumber,
      addressLike:address,
      bankNameLike:bankName,

    };    
    searchBusinessData(changePageBusinessQueryVo);
  
  };

  const editBusinessData = (tableMeta) => {
     
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setOpen(!open);
    const editBusinessDto = data[row];
    setEditBusinessDto(editBusinessDto);
  };


  const deleteBusinessData = (tableMeta) => {
     
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setDeleteOpen(!deleteOpen);
    const editBusinessDto = data[row];
  
    setBusinessId(editBusinessDto.id)
  };
 
  const options = {
    filterType: "dropdown",
    responsive: "vertical",
    serverSide: true,
    count: count,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    sortOrder: { sortOrder },
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    onTableChange: (action, tableState) => {
      console.log(action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          this.sort(tableState.page, tableState.sortOrder);
          break;
        default:
          console.log("action not handled.");
      }
    },
  };

  const initData = (index, pageSize) => {

    let changePageBusinessQueryVo = {
      index:index,
      pageSize:10,
      id:id,
      bankAccount:bankAccount,
      phoneNumber: phoneNumber,
      addressLike:address,
      bankNameLike:bankName,
      nameLike:businessName,

    };    
    searchBusinessData(changePageBusinessQueryVo);
     
  };

  const searchBusinessData = async (businessQueryVo) => {

    try{
      setIsLoading(true);
       
      let searchBusinessResponse = await searchBusinessDto(businessQueryVo);
      let code = searchBusinessResponse.code;
      let data = searchBusinessResponse.data;
      if(ERROR_CODE_SUCCESS==code){
        setData(data.records);
        setCount(data.total);
      }else{
        console.log("searchBusinessData===load failed");
      }
      setIsLoading(false);
      setPage(businessQueryVo.index);
    }catch(e){
      console.log("searchBusinessData====error: " ,e)
    }
    
  
   
  };
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API

    initData(page, 10);
    setRefreshFlag(false);
  }, [refreshFlag]);

  useEffect(() => {
    console.log("===searchBusinessData=");
    if (searchRefreshFlag) {
      console.log("searchName===", searchName);
    }

    

    let firstLoadBusinessQueryVo = {
        index:page,
        pageSize:10,
    };
    searchBusinessData(firstLoadBusinessQueryVo);

    setSearchRefreshFlag(false);
  }, [searchRefreshFlag]);

  const configurationData = {
    page: 0,
    count: 1,
    rowsPerPage: 10,
    sortOrder: {},
    data: [["Loading Data..."]],
    columns: [
      {
        name: "id",
        label: "Id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            if (value) {
              return new BigNumber(value).toString();
            }
            return value;
          },
        },
      },

      {
        name: "name",
        label: "Company Name",
        options: {},
      },
      {
        name: "phoneNumber",
        label: "Phone",
        options: {},
      },
      
      {
        name: "bankName",
        label: "Bank name",
        options: {},
      },
      {
        name: "bankAccount",
        label: "Bank account",
        options: {},
      },

     
      {
        name: "address",
        label: "Company addresss",
        options: {},
      },
      
      {
        name: "creationTime",
        label: "Create Time",
        options: {},
      },
      {
        name: "Action",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Box>
                <Stack direction="row" spacing={2}>
                <Item elevation={0}> 
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) => editBusinessData(tableMeta)}
                >
                  Edit
                </Button>
                </Item>
                <Item elevation={0}> 
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) => deleteBusinessData(tableMeta)}
                >
                  Delete
                </Button>
                </Item>
                </Stack>
              
              </Box>
            );
          },
        },
      },
    ],
  };


  const { control, handleSubmit,reset } = useForm({
    defaultValues: {
      id: "",
      bankAccount:"",
      phoneNumber:"", 
      address:"",
      bankName:"",
      name:"",
    }

    
  });

  const businessSubmitSearch = (data) => {

    // setSearchRefreshFlag(true);
    let bankAccountString = data.bankAccount || data.bankAccount.trim()  ? data.bankAccount : null;
    let phoneNumberString = data.phoneNumber || data.phoneNumber.trim() ? data.phoneNumber : null;
    let addressLikeString = data.address || data.address.trim() ? data.address : null;
    let bankNameLikeString = data.bankName || data.bankName.trim() ? data.bankName : null;
    let nameLikeString = data.name || data.name.trim() ? data.name : null;
    setId(data.id);
    setBankAccount(bankAccountString);
    setPhoneNumber(phoneNumberString);
    setAddress(addressLikeString);
    setBankName(bankNameLikeString);
    setBusinessName(nameLikeString);
    let businessQueryVo = {
      index: page,
      pageSize:10,
      id:data.id,
      bankAccount:bankAccountString,
      phoneNumber: phoneNumberString,
      addressLike:addressLikeString,
      bankNameLike:bankNameLikeString,
      nameLike:nameLikeString,

    }
    searchBusinessData(businessQueryVo);

  }

  const businessResetSearch = () => {
    reset();
    setId();
    setBankAccount();
    setPhoneNumber();
    setAddress();
    setBankName();
    setBusinessName();
  }

  const uploadBusinessExcel = async (e) => {
    if (e.target.files) {
      setBusinessExcelFile(e.target.files[0]);
    }

    console.log(e.target.files[0]);

    try{

      let formData = new FormData();
      formData.append('file', e.target.files[0]);

      importMerchantExcel(formData);
   
    }catch(e){
      console.log("uploadBusinessExcel===error",e);
    }
  };

  const uploadStoreExcel = async (e) => {
    

    console.log(e.target.files[0]);

    try{

      let formData = new FormData();
      formData.append('file', e.target.files[0]);

      importStoreExcel(formData);
   
    }catch(e){
      console.log("uploadBusinessExcel===error",e);
    }
  };


  const uploadAcquirerExcel = async (e) => {
   

    console.log(e.target.files[0]);

    try{

      let formData = new FormData();
      formData.append('file', e.target.files[0]);

      importAcquirerExcel(formData);
   
    }catch(e){
      console.log("uploadBusinessExcel===error",e);
    }
  };

  const handleDeleteNoClose = () => {
     
    setDeleteOpen(false);
    setBusinessId();
  };

  const handleDeleteYesClose = async () => {
     
    try{
      
      let businessDto = {
        id:businessId,
      }
      let response=deleteBusinessDto(businessDto);
      setDeleteOpen(false);
      setBusinessId();
      setRefreshFlag(true);
    }catch(e){
      console.log("handleDeleteYesClose===error",e);
    }
    
  };
  return (
    <div>
       
      <form >
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={3}>
        <Grid item xs={4}>
        <Paper className={classes.paper}>Id:
        <Controller
            name="id"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
            </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>BankAccount:
          <Controller
            name="bankAccount"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
            </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>Phone:
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
          </Paper>
        </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
        <Grid item xs={4}>
          <Paper className={classes.paper}>Company address:
          <Controller
            name="address"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>Company Name:
          <Controller
            name="name"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
         
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>Bank Name:
          <Controller
            name="bankName"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
          </Paper>
        </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
       
        <Grid item xs={4}>
          <Paper className={classes.paper}>
          <Button onClick={handleSubmit(businessSubmitSearch)} variant={"outlined"} color="primary">Search</Button></Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}><Button onClick={() => businessResetSearch()} variant={"outlined"} color="primary">Reset</Button></Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>  <Button onClick={addBusiness} variant={"outlined"} color="primary">add</Button></Paper>
        </Grid>
        </Grid>


        <Grid container item xs={12} spacing={3}>
       
        <Grid item xs={4}>
          <Paper className={classes.paper}>
          Upload company excel
          <input type="file" onChange={uploadBusinessExcel}  />
           </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
          Upload store excel
          <input type="file" onChange={uploadStoreExcel}  />
           </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.paper}>
          Upload acquirer excel
          <input type="file" onChange={uploadAcquirerExcel}  />
           </Paper>
        </Grid>
        </Grid>
      </Grid>
      </form>
      <MUIDataTable
        title={
          <Typography variant="h6">
            Company list
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={data}
        columns={configurationData.columns}
        options={options}
      />

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle sx={{ m: 2, p: 5 }}>Edit company</DialogTitle>
        <DialogContent>
          <BusinessEditForm
            handleEditClose={handleClose}
            handleEditSubmit={handleEditSubmit}
            editBusinessDto={editBusinessDto}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={addOpen} onClose={handleAddClose} fullWidth maxWidth="lg">
        <DialogTitle sx={{ m: 2, p: 5 }}>Add company</DialogTitle>
        <DialogContent>
          <Paper>
            <BusinessForm
              handleSubmit={handleSubmitBusiness}
              handleAddClose={handleAddClose}
            />
          </Paper>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>


      <Dialog
        open={deleteOpen}
 
        keepMounted
        onClose={handleDeleteClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Do you want to delete this company?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteNoClose}>No</Button>
          <Button onClick={handleDeleteYesClose}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
