import request from "./ConfigRequest";
import axios from 'axios';
import {ERROR_CODE_SUCCESS} from "../components/common/constant/ResponseErrorCodeConstant";
export const pageBusinessDto = (index, size)=>{
    return request({
        url: 'business/pageBusinessDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveBusiness = (businessDto)=>{
    return request({
        url: 'business/saveBusinessDto',
        method: 'post',
        data: businessDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateBusinessDto = (businessDto)=>{
    return request({
        url: 'business/updateBusinessDto',
        method: 'put',
        data: businessDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchBusinessDto = (businessQueryVo)=>{
    return request({
        url: 'business/pageBusinessDto',
        params: businessQueryVo,
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};

export const searchBusinessLikeDto = (index, size, searchLikeKey) => {
    return request({
      url: "business/searchLikeBusinessDto",
      params: {
        index,
        size,
        searchLikeKey,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  };


  export const importMerchantExcel = (businessExcelFile) => {

    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }
    const loginToken = localStorage.getItem("login");

    if (loginToken) {
      config.headers["Authorization"] = `Bearer ${loginToken}`;
    }
     

    axios.post("/manage-rest/businessSecond/importMerchantExcel", businessExcelFile, config)
    .then (res => {
        console.log(res.data);
      
        let code = res.data.code;

        if(ERROR_CODE_SUCCESS==code){
            alert("Company upload success, Please refresh ");
        }else{
            alert("Company upload error"+res.data.message);
        }
    })
    
  };


  export const importStoreExcel = (storeExcelFile) => {

    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }
    const loginToken = localStorage.getItem("login");

    if (loginToken) {
      config.headers["Authorization"] = `Bearer ${loginToken}`;
    }
     

    axios.post("/manage-rest/businessSecond/importStoreExcel", storeExcelFile, config)
    .then (res => {
        console.log(res.data);
      
        let code = res.data.code;

        if(ERROR_CODE_SUCCESS==code){
            alert("Store upload success, Please refresh ");
        }else{
            alert("Store upload error"+res.data.message);
        }
    })
    
  };


  export const importAcquirerExcel = (acquirerExcelFile) => {

    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }
    const loginToken = localStorage.getItem("login");

    if (loginToken) {
      config.headers["Authorization"] = `Bearer ${loginToken}`;
    }
     

    axios.post("/manage-rest/businessSecond/importAcquirerExcel", acquirerExcelFile, config)
    .then (res => {
        console.log(res.data);
      
        let code = res.data.code;

        if(ERROR_CODE_SUCCESS==code){
            alert("Acquirer upload success, Please refresh ");
        }else{
            alert("Acquirer upload error"+res.data.message);
        }
    })
    
  };


  export const searchBusinessLikeDtoV1= (searchBusinessLikeDto) => {
    return request({
      url: "business/searchLikeBusinessDto",
      params: {
        index:searchBusinessLikeDto.index,
        size:searchBusinessLikeDto.size,
        searchLikeKey:searchBusinessLikeDto.searchLikeKey,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  };


  export const deleteBusinessDto = (businessDto)=>{
    return request({
        url: 'business/deleteBusinessDto',
        method: 'delete',
        data: businessDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
