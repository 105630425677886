import request from "./ConfigRequest";

export const pageStoreDto = (storeQueryVo) => {
  return request({
    url: "store/pageStoreDto",
    params:{
      index:storeQueryVo.index,
      pageSize:storeQueryVo.pageSize,
      id:storeQueryVo.id,
      storeNameLike:storeQueryVo.storeNameLike,
      businessNameLike:storeQueryVo.businessNameLike,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const saveStore = (storeDto) => {
  return request({
    url: "store/saveStoreDto",
    method: "post",
    data: storeDto,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateStoreDto = (storeDto) => {
  return request({
    url: "store/updateStoreDto",
    method: "put",
    data: storeDto,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const searchStoreDto = (index, size, name) => {
  return request({
    url: "store/pageStoreDto",
    params: {
      index,
      size,
      name,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const searchStoreLikeDto = (index, size, searchLikeKey) => {
  return request({
    url: "store/searchLikeStoreDto",
    params: {
      index,
      size,
      searchLikeKey,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const registerStoreAlipayDto = (businessVo) => {
  return request({
    url: "business/alipayMerchantRegistration",
    method: "put",
    data: businessVo,
    headers: {
      "Content-Type": "application/json",
    },
  });
};


export const alipayInquiryMerchantRegistrationStatus = (businessVo) => {
  return request({
    url: "business/alipayInquiryMerchantRegistrationStatus",
    method: "put",
    data: businessVo,
    headers: {
      "Content-Type": "application/json",
    },
  });
};



export const generateStoreAlipayStaticQrcodeImage = (storeVo) => {
  return request({
    url: "store/generateStoreAlipayStaticQrcodeImage",
    method: "post",
    data: storeVo,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const selectStoreDtoExport = (storeQueryVo) => {
  return request({
    url: "store/selectStoreDtoExport",
    params:{
      id:storeQueryVo.id,
      storeNameLike:storeQueryVo.storeNameLike,
      businessNameLike:storeQueryVo.businessNameLike,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    'responseType': 'blob'
  });
};


export const selectAcquireStoreDtoExport = (storeQueryVo) => {
  return request({
    url: "store/selectAcquireStoreDtoExport",
    params:{
      id:storeQueryVo.id,
      storeNameLike:storeQueryVo.storeNameLike,
      businessNameLike:storeQueryVo.businessNameLike,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    'responseType': 'blob'
  });
};


export const generateStoreWechatH5QrcodeImage = (storeVo) => {
  return request({
    url: "store/generateStoreWechatH5QrcodeImage",
    method: "post",
    data: storeVo,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteReferralStoreId = (storeVo) => {
  return request({
    url: "store/deleteReferralStoreId",
    method: "put",
    data: storeVo,
    headers: {
      "Content-Type": "application/json",
    },
  });
};