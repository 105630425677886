import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import TerminalEditForm from "./TerminalEditForm";
import TerminalForm from "./TerminalForm";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

import {
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Box,
  makeStyles,
  Grid,
  Input,

} from "@material-ui/core";

import {
  pageTerminalDto,
  searchTerminalDto,
} from "../../service/TerminalRequest";

import { TerminalAddButtonControl } from "./style/TerminalStyle";
import StoreIdSelect from "../common/StoreIdSelect";
import BusinessIdSelect from "../common/BusinessIdSelect";


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

export default function TerminalTable(props) {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([["Loading Data..."]]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(0);
  const [open, setOpen] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const [editTerminalDto, setEditTerminalDto] = useState({});

  const [refreshFlag, setRefreshFlag] = useState(false);

  const [searchName, setSearchName] = useState("");

  const [searchRefreshFlag, setSearchRefreshFlag] = useState(false);
  const [storeId,setStoreId] = useState();

  const [storeLabelText,setStoreLabelText] = useState("");

  const [store, setStore] = useState();
  const [id,setId] = useState();
  const [deviceType,setDeviceType] = useState();
  const [serialNumberLike,setSerialNumberLike] = useState();



  const { control, handleSubmit,reset } = useForm({
    defaultValues: {
      id: '',
      serialNumberLike:'',
    }

    
  });

  const searchTerminal = () => {
    console.log("======searchTerminal", searchName);
    setSearchRefreshFlag(true);
  };

  const addTerminal = () => {
    console.log("======handle add addTerminal");
    setAddOpen(!addOpen);
  };

  const handleClose = () => {
    console.log("======handle close");
    setOpen(!open);
  };

  const handleEditSubmit = () => {
    console.log("======handle edit submit");
    setOpen(!open);
    debugger;

    setTimeout(() => {
      setRefreshFlag(true);
    }, 10);
  };

  const handleAddSubmit = () => {
    debugger;
    console.log("==handleAddSubmit=");

    setAddOpen(!addOpen);

    setRefreshFlag(true);
  };

  const handleAddClose = () => {
    debugger;
    console.log("======handle add close");
    setAddOpen(!addOpen);
    setTimeout(() => {
      setRefreshFlag(true);
    }, 1000);
  };

  const changePage = (page, sortOrder) => {
    setIsLoading(true);
    debugger;
    console.log("===page", page);
    page = page + 1;
    let terminalQueryVo = {

      index:page,
      pageSize:10,
      id: id ? id: null,
      serialNumberLike: serialNumberLike? serialNumberLike: null,
      deviceType: deviceType ? deviceType: null,
      storeId:storeId ? storeId.toString() : null,
     }

    
    initData(terminalQueryVo);
  };

  const editTerminalData = (tableMeta) => {
     
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setOpen(!open);
    const editTerminalDto = data[row];
    setEditTerminalDto(editTerminalDto);
  };
  console.log("count===", count);
  const options = {
    filterType: "dropdown",
    responsive: "vertical",
    serverSide: true,
    count: count,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    sortOrder: { sortOrder },
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    onTableChange: (action, tableState) => {
      console.log(action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          this.sort(tableState.page, tableState.sortOrder);
          break;
        default:
          console.log("action not handled.");
      }
    },
  };

  const initData =  async (terminalQueryVo) => {
    setIsLoading(true);
 
  
    try{
      let initTerminalResponseDto = await pageTerminalDto(terminalQueryVo);

      console.log("initTerminalResponseDto=====", initTerminalResponseDto);
      
      
      if(initTerminalResponseDto && initTerminalResponseDto.data){
        setData(initTerminalResponseDto.data.records);
        setCount(initTerminalResponseDto.data.total);
      }

      setIsLoading(false);
      setPage(terminalQueryVo.index);
    }catch(e){
      console.log("initData===error: " + e);
    }
    setIsLoading(false);
  };

  const searchTerminalData = (index, pageSize, searchName) => {
    setIsLoading(true);
 
    searchTerminalDto(index, pageSize, searchName).then((res) => {
      // const albums = res.albums;
      console.log("====data", res.data);

      if (res && res.data) {
        setData(res.data.records);
        setCount(res.data.total);
      }

      setIsLoading(false);

      setPage(index);
    });
    setIsLoading(false);
  };
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    let terminalQueryVo = {

      index:1,
      pageSize:10,
     }
    initData(terminalQueryVo);
    setRefreshFlag(false);
  }, [refreshFlag]);

  useEffect(() => {
    
    console.log("===searchTerminalData=");
    if (searchRefreshFlag) {
      console.log("searchName===", searchName);
    }

    if (!searchName) return;
    searchTerminalData(page, 10, searchName);

    setSearchRefreshFlag(false);
  }, [searchRefreshFlag]);

  const configurationData = {
    page: 0,
    count: 1,
    rowsPerPage: 10,
    sortOrder: {},
    data: [["Loading Data..."]],
    columns: [
      {
        name: "id",
        label: "id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            return value.toString();
          },
        },
      },

      // {
      //   name: "ownership",
      //   label: "Ownership",
      //   options: {},
      // },
      {
        name: "serialNumber",
        label: "SerialNumber",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.

            console.log("customBodyRender");
            console.dir(tableMeta);
            return value;
          },
        },
      },
      {
        name: "businessName",
        label: "Company Name",
        options: {},
      },
      {
        name: "storeName",
        label: "Store Name",
        options: {},
      },
      {
        name: "deviceType",
        label: "Device Type",
        options: {},
      },  {
        name: "sunmiTerminalVersion",
        label: "SunmiTerminalVersion",
        options: {},
      },
      {
        name: "modelNumber",
        label: "Model Number",
        options: {},
      },
      {
        name: "simNumber",
        label: "Sim Number",
        options: {},
      },
      {
        name: "status",
        label: "Status",
        options: {},
      },
      {
        name: "terminalName",
        label: "Terminal Name",
        options: {},
      },
      {
        name: "creationTime",
        label: "Create Time",
        options: {},
      },
      {
        name: "Action",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) => editTerminalData(tableMeta)}
                >
                  Edit
                </Button>
              </Box>
            );
          },
        },
      },
    ],
  };


  const setStoreIdChange = (storeText) => {
    debugger;
    console.log("setStoreIdChange===", storeText);
    if (storeText) {
      setStoreId(storeText.id);
    }
  };

  const clearSelectStoreId =() => { 
    console.log("Clear storeId");
    setStoreId();
  }

  const terminalReset = () => { 
    console.log("terminalReset");
    reset();
    setStoreId();
  }

  const onTerminalSubmit = (data) => {

    console.log(data);

    console.log(storeId);
    setId(data.id);
    setSerialNumberLike(data.serialNumberLike);
    setDeviceType(data.deviceType.value);

  
   let terminalQueryVo = {

    index:1,
    pageSize:10,
    id: data.id ? data.id: null,
    serialNumberLike: data.serialNumberLike? data.serialNumberLike: null,
    deviceType: data.deviceType.value ? data.deviceType.value: null,
    storeId:storeId ? storeId.toString() : null,
   }

   initData(terminalQueryVo);
    

  }
  return (
    <div>

      <form>
      <Grid container spacing={3}>
        <Grid item xs={4}>
        <Paper className={classes.paper}>Id:
              <Controller
              name="id"
              control={control}
              render={({ field }) => <Input {...field} />}
              />
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.paper}> 
             <label>Device type</label>
          <Controller
            name="deviceType"
            control={control}
            defaultValue=""
            render={({ field }) => <Select 
              {...field} 
              options={[
                { value: "N5", label: "N5" },
                { value: "imin", label: "imin" },
                { value: "sumi", label: "sumi" },
                { value: "mobile", label: "mobile" },
                { value: "other", label: "other" },
              ]} 
            />
            }
          />
       
        </Paper>
        </Grid>



        <Grid item xs={4}>
        <Paper className={classes.paper}>serialNumber:
              <Controller
              name="serialNumberLike"
              control={control}
              render={({ field }) => <Input {...field} />}
              />
          </Paper>
        </Grid>

      </Grid>


        <Grid container spacing={3}>
          <Grid item xs={4}>
          <Paper className={classes.paper}> 
              <StoreIdSelect 
                          setStoreIdChange={setStoreIdChange}
                          store = {store}
                          clearSelectStoreId={clearSelectStoreId} 
                          storeLabelText ={storeLabelText}/>
              </Paper>
          </Grid>

          <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={handleSubmit(onTerminalSubmit)} variant={"outlined"} color="primary">Query</Button></Paper>
        </Grid>
        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={() => terminalReset()} variant={"outlined"} color="primary">Reset</Button></Paper>
        </Grid>
        </Grid>

      </form>
      {/* <div>
        <TextField
          id="serialNumber"
          label="Serial Number"
          value={searchName}
          variant="standard"
          onChange={(e) => setSearchName(e.target.value)}
        />
      </div>

      <TerminalAddButtonControl>
        <Button
          className="btn right"
          variant="outlined"
          onClick={searchTerminal}
          color="primary"
        >
          Search
        </Button>
        <Button
          className="btn right"
          variant="outlined"
          onClick={addTerminal}
          color="primary"
        >
          Add
        </Button>
      </TerminalAddButtonControl> */}

      <MUIDataTable
        title={
          <Typography variant="h6">
            Terminal list
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={data}
        columns={configurationData.columns}
        options={options}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "50%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Edit Terminal</DialogTitle>
        <DialogContent>
          <TerminalEditForm
            handleEditClose={handleClose}
            handleEditSubmit={handleEditSubmit}
            editTerminalDto={editTerminalDto}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={addOpen} onClose={handleAddClose} fullWidth maxWidth="lg">
        <DialogTitle>Add Terminal</DialogTitle>
        <DialogContent>
          <Paper>
            <TerminalForm
              handleSubmit={handleAddSubmit}
              handleAddClose={handleAddClose}
            />
          </Paper>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
