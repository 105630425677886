import ApiConfigEditForm from "./ApiConfigEditForm";
import ApiConfigForm from "./ApiConfigForm";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";

import {
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Box,
  makeStyles,
  styled,
  Grid,
  DialogContentText,
} from "@material-ui/core";

import {
  pageApiConfigDto,
  searchApiConfigDto,
  deleteApiConfigDto,
} from "../../service/ApiConfigRequest";
import Stack from '@mui/material/Stack';
import { ApiConfigAddButtonControl } from "./style/ApiConfigStyle";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
 

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(2),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));
export default function ApiConfigTable() {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([["Loading Data..."]]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(0);
  const [open, setOpen] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const [editApiConfigDto, setEditApiConfigDto] = useState({});

  const [refreshFlag, setRefreshFlag] = useState(false);

 

  const [searchRefreshFlag, setSearchRefreshFlag] = useState(false);
  const [companyName,setCompanyName] = useState();
  const [pageSize,setPageSize]= useState(10);

  const [deleteApiConfigOpen,setDeleteApiConfigOpen] = useState(false);
  const [id,setId] = useState();
  const searchApiConfig = () => {
    console.log("======searchApiConfig", searchName);
    setSearchRefreshFlag(true);
  };

  const addApiConfig = () => {
    console.log("======handle add addApiConfig");
    setAddOpen(!addOpen);
  };

  const handleClose = () => {
    console.log("======handle close");
    setOpen(!open);
  };

  const handleEditSubmit = () => {
    console.log("======handle edit submit");
    setOpen(!open);
    debugger;

    setTimeout(() => {
      setRefreshFlag(true);
    }, 10);
  };

  const handleSubmit = () => {
    debugger;
    console.log("==handleSubmit=");

    setAddOpen(!addOpen);

    setRefreshFlag(true);
  };

  const handleAddClose = () => {
    debugger;
    console.log("======handle add close");
    setAddOpen(!addOpen);
    setTimeout(() => {
      setRefreshFlag(true);
    }, 1000);
  };

  const changePage = (page, sortOrder) => {
    setIsLoading(true);
    debugger;
    console.log("===page", page);
    page = page + 1;
    initData(page, 10);
  };

  const editApiConfigData = (tableMeta) => {
    
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setOpen(!open);
    const editApiConfigDto = data[row];
    setEditApiConfigDto(editApiConfigDto);
  };
  console.log("count===", count);
  const options = {
    filterType: "dropdown",
    responsive: "vertical",
    serverSide: true,
    count: count,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    sortOrder: { sortOrder },
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    onTableChange: (action, tableState) => {
      console.log(action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          this.sort(tableState.page, tableState.sortOrder);
          break;
        default:
          console.log("action not handled.");
      }
    },
  };

  const initData = async(apiConfigQueryDto) => {

    try{
      setIsLoading(true);
      
      let initResponseData= await pageApiConfigDto(apiConfigQueryDto);
       
      // const albums = res.albums;
      console.log("====data", initResponseData.data);
      if (initResponseData && initResponseData.data) {
        setData(initResponseData.data.records);
        setCount(initResponseData.data.total);
      }

      setIsLoading(false);

      setPage(apiConfigQueryDto.index);
       
      
    }catch(e){
      console.log("initData===error===",e)
    }
    
  };

  const searchApiConfigData =async () => {

    try{

      let apiConfigQueryDto = {
        index:page,
        pageSize:pageSize,
        businessNameLike:companyName
      };

      initData(apiConfigQueryDto);
    }catch(e){
      console.log("searchApiConfigData===error==",e);
    }
    
  };
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    let  apiConfigQueryDto = {
        index:page,
        pageSize:10,
    };
    initData(apiConfigQueryDto);
    setRefreshFlag(false);
  }, [refreshFlag]);

  useEffect(() => {
 
    let  apiConfigQueryVo = {
      index:page,
      pageSize:10
    };
    searchApiConfigData(apiConfigQueryVo);

  }, []);

  const configurationData = {
    page: 0,
    count: 1,
    rowsPerPage: 10,
    sortOrder: {},
    data: [["Loading Data..."]],
    columns: [
      {
        name: "id",
        label: "id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            return value.toString();
          },
        },
      },
      {
        name: "secretKey",
        label: "Secret key",
        options: {},
      },
      
      {
        name: "businessName",
        label: "Company name",
        options: {},
      },
      {
        name: "partnerPublicKey",
        label: "Partner Public Key",
        width:20,
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.

            console.log("customBodyRender");
            console.dir(tableMeta);
            if(value){
              return value.substring(0,10)+"...";
            }
            return value;
          },
        },
      },
      {
        name: "apiSignFlag",
        label: "Api sign flag",
        options: {},
      },
      {
        name: "signMethod",
        label: "sign method",
        options: {},
      },
      {
        name: "apiMerchantType",
        label: "Api merchant type",
        options: {},
      },
      {
        name: "masterMerchantName",
        label: "Master merchant name",
        options: {},
      },
      {
        name: "notificationResponseSign",
        label: "Notification response sign",
        options: {},
      },
      {
        name: "partnerNotificationSwitch",
        label: "Partner notification switch",
        options: {},
      },
      {
        name: "creationTime",
        label: "Create Time",
        options: {},
      },
      {
        name: "Action",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Box>
                <Stack spacing={2} >
                    <Item elevation={0}> 
                      <Stack direction="row" spacing={2}>
                        <Item elevation={0}>
                     
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={(e) => editApiConfigData(tableMeta)}
                          >
                            Edit
                          </Button>
                      </Item>
                      <Item elevation={0}>
                     
                     <Button
                       variant="outlined"
                       size="small"
                       color="primary"
                       onClick={(e) => deleteApiConfigData(tableMeta)}
                     >
                       Delete
                     </Button>
                 </Item>
                        
                    </Stack>
                  </Item>
                   
                </Stack>
              </Box>
            );
          },
        },
      },
    ],
  };

  const companyNameText =(e)=>{
      console.log("companyNameText===",e.target.value);
      setCompanyName(e.target.value);
  }

  const searchData=async ()=>{
      try{
         
        searchApiConfigData()
      }catch(e){
        console.log("searchData===error=",e)
      }
  }


  const resetData = ()=>{
    
      console.log("resetData");
      setCompanyName();

      console.log(companyName);
  }

  const handleDeleteConfigurationNoClose = () => {
     
    setDeleteApiConfigOpen(false);
    setId();
  };


    const handleDeleteConfigurationYesClose = async () => {
       
      try{
        
        let apiConfigDto = {
          id:id,
        }
        let deleteResponse = await deleteApiConfigDto(apiConfigDto)
        
        setDeleteApiConfigOpen(false);
        setId();
        setRefreshFlag(true);
      }catch(e){
        console.log("handleDeleteConfigurationYesClose===error",e);
      }
      
    };

    const deleteApiConfigData = (tableMeta) => {
    
      const row = tableMeta.rowIndex;
      console.log("row", row);
      setDeleteApiConfigOpen(!deleteApiConfigOpen);
      const editApiConfigDto = data[row];
      setId(editApiConfigDto.id);
    };
  return (
    <div>
       <Box  component="form"   autoComplete="off"  sx={{
               '& .MuiTextField-root': { m: 1, width: '25ch' },
             }}   >
    <Grid container spacing={3}>
     <Grid item xs={4}>
          <Paper className={classes.paper}>
           <TextField id="companyName" label="Company Name"  value={companyName}   onChange={(event) => {
              setCompanyName(event.target.value);
            }}  />
         </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}><Button onClick={searchData}  variant={"outlined"} color="primary">Search</Button></Paper>
          </Grid>
          <Grid item xs={4}>
          <Paper className={classes.paper}><Button onClick={resetData}  type="reset" variant={"outlined"} color="primary">Reset</Button></Paper>
        </Grid>
    </Grid>
    <Grid  container spacing={3}>
        <Grid item xs={4}>
          <Paper className={classes.paper}><Button onClick={addApiConfig}   variant={"outlined"} color="primary">Add</Button></Paper>
        </Grid>
    </Grid>
    </Box>
    
      <MUIDataTable
        title={
          <Typography variant="h6">
            ApiConfig list
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={data}
        columns={configurationData.columns}
        options={options}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "50%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Edit ApiConfig</DialogTitle>
        <DialogContent>
          <ApiConfigEditForm
            handleEditClose={handleClose}
            handleEditSubmit={handleEditSubmit}
            editApiConfigDto={editApiConfigDto}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={addOpen} onClose={handleAddClose} fullWidth maxWidth="lg">
        <DialogTitle>Add ApiConfig</DialogTitle>
        <DialogContent>
        <ApiConfigForm
              handleSubmit={handleSubmit}
              handleAddClose={handleAddClose}
            />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      <Dialog
        open={deleteApiConfigOpen}
 
        keepMounted
        onClose={handleDeleteConfigurationNoClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Do you want to delete this configuration?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfigurationNoClose}>No</Button>
          <Button onClick={handleDeleteConfigurationYesClose}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
