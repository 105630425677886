import AddressIdSelect from "../common/AddressIdSelect";
import BankDetailIdSelect from "../common/BankDetailIdSelect";
import BusinessIdSelect from "../common/BusinessIdSelect";
import React, { useState } from "react";
import UserIdSelect from "../common/UserIdSelect";
import { updateStoreDto } from "../../service/StoreRequest";
import { StoreFormBottonControl } from "./style/StoreStyle";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {
  TextField,
  Button,
  Box,
  Container,
  makeStyles,
  Grid,
  Paper,
} from "@material-ui/core";

import StoreIdSelect from "../common/StoreIdSelect";
const userStyles = makeStyles((theme) => ({
  field: {
    marginTop: 20,
    marginBottom: 10,
  },
  hidden: {
    display: "none",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },

  divider: {
    margin: theme.spacing(2, 0),
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
export default function StoreEditForm({
  handleEditClose,
  handleEditSubmit,
  editStoreDto,
}) {
  const classes = userStyles();

  const [id, setId] = useState(editStoreDto.id);
  const [status, setStatus] = useState(editStoreDto.status);
  const [name, setName] = useState(editStoreDto.name);
  const [foreignName, setForeignName] = useState(editStoreDto.foreignName);
  const [industryCode, setIndustryCode] = useState(editStoreDto.industryCode);
  const [storeDescription, setStoreDescription] = useState(
    editStoreDto.storeDescription,
  );
  const [storeLanguage, setStoreLanguage] = useState(
    editStoreDto.storeLanguage,
  );
  const [operatingDays, setOperatingDays] = useState(
    editStoreDto.operatingDays,
  );
  const [userId, setUserId] = useState(editStoreDto.userId);

  const [operatingHours, setOperatingHours] = useState(
    editStoreDto.operatingHours,
  );
  const [voidPassword, setVoidPassword] = useState(editStoreDto.voidPassword);
  const [isOnlineStore, setIsOnlineStore] = useState(
    editStoreDto.isOnlineStore,
  );
  const [settlementEmails, setSettlementEmails] = useState(
    editStoreDto.settlementEmails,
  );
  const [bankDetailId, setBankDetailId] = useState(editStoreDto.bankDetailId);
  const [addressId, setAddressId] = useState(editStoreDto.addressId);
  const [businessId, setBusinessId] = useState(editStoreDto.businessId);
  const [bank, setBank] = useState(editStoreDto.bankName);
  const [address, setAddress] = useState(editStoreDto.address);
  const [business, setBusiness] = useState(editStoreDto.businessName);
  const [user, setUser] = useState(editStoreDto.userName);

  const [bankAccount, setBankAccount] = useState(editStoreDto.bankAccount);
  const [bankName, setBankName] = useState(editStoreDto.bankName);

  const [storeMcc, setStoreMcc] = useState(editStoreDto.storeMcc);
  const [registerStatus,setRegisterStatus] = useState(editStoreDto.registerStatus);
  const [storeRemark,setStoreRemark] = useState(editStoreDto.storeRemark);
  const [settlementBankFileShowNameFlag,setSettlementBankFileShowNameFlag] = useState(editStoreDto.settlementBankFileShowNameFlag);
  const [refundDifferentDayFlag,setRefundDifferentDayFlag] = useState(editStoreDto.refundDifferentDayFlag);
  const [belongFlag,setBelongFlag] = useState(editStoreDto.belongFlag);
  const [referralStoreId,setReferralStoreId] = useState(editStoreDto.referralStoreId);
  const [referralStoreName,setReferralStoreName] = useState(editStoreDto.referralStoreName);
 

  const handleSubmitData = (e) => {
    e.preventDefault();

    let saveStoreDto = {
      id: id,
      status: status,
      name: name,
      foreignName: foreignName,
      industryCode: industryCode,
      storeDescription: storeDescription,
      storeLanguage: storeLanguage,
      operatingDays: operatingDays,
      operatingHours: operatingHours,
      userId: userId,
      voidPassword: voidPassword,
      isOnlineStore: isOnlineStore,
      settlementEmails: settlementEmails,
      bankDetailId: bankDetailId,
      addressId: addressId,
      businessId: businessId,
      referralStoreId:referralStoreId,
      address: address,
      storeMcc:storeMcc,
      registerStatus:registerStatus,
      storeRemark:storeRemark,
      settlementBankFileShowNameFlag:settlementBankFileShowNameFlag,
      refundDifferentDayFlag:refundDifferentDayFlag,
      belongFlag:belongFlag,

    };
    updateStoreDto(saveStoreDto);

    handleEditSubmit();
  };

  const setAddressIdChange = (addressText) => {
    console.log("setAddressIdChange===", addressText);
    if (addressText) {
      setAddressId(addressText.id);
    }
  };

  const setBankDetailIdChange = (bankDetailText) => {
    console.log("setBankDetailIdChange===", bankDetailText);
    if (bankDetailText) {
      setBankDetailId(bankDetailText.id);
    }
  };

  const setBusinessIdChange = (businessText) => {
    console.log("setBusinessIdChange===", businessText);
    if (businessText) {
      setBusinessId(businessText.id);
    }
  };

  const setStoreIdChange = (userText) => {
    console.log("setStoreIdChange===", userText);
    if (userText) {
      setReferralStoreId(userText.id);
    }
  };

  const clearStoreId = () => {
      setReferralStoreId();
  }

  const handleSetSettlementBankFileShowNameFlagChange = (event) => {
    setSettlementBankFileShowNameFlag(event.target.value);
  };
  return (
    <div>
      <Container>
        <form noValidate autoComplete="off" onSubmit={handleSubmitData}>
          <TextField id="id" hidden value={id} className={classes.hidden} />
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <TextField
                  onChange={(e) => setName(e.target.value)}
                  className={classes.field}
                  id="standard-disabled"
                  label="Name"
                  variant="standard"
                  value={name}
                />
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <TextField
                  id="status"
                  className={classes.field}
                  label="Status"
                  variant="standard"
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <TextField
                  id="foreignName"
                  className={classes.field}
                  label="Foreign Name"
                  onChange={(e) => setForeignName(e.target.value)}
                  variant="standard"
                  value={foreignName}
                />
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <TextField
                  id="industryCode"
                  className={classes.field}
                  label="Industry Code"
                  variant="standard"
                  onChange={(e) => setIndustryCode(e.target.value)}
                  value={industryCode}
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <TextField
                  id="storeDescription"
                  className={classes.field}
                  label="Store Description"
                  onChange={(e) => setStoreDescription(e.target.value)}
                  variant="standard"
                  value={storeDescription}
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <TextField
                  id="storeLanguage"
                  className={classes.field}
                  label="Store Language"
                  variant="standard"
                  onChange={(e) => setStoreLanguage(e.target.value)}
                  value={storeLanguage}
                />
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <TextField
                  id="operatingDays"
                  className={classes.field}
                  label="Operating Days"
                  onChange={(e) => setOperatingDays(e.target.value)}
                  variant="standard"
                  value={operatingDays}
                />
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <TextField
                  id="operatingHours"
                  className={classes.field}
                  label="Operating Hours"
                  onChange={(e) => setOperatingHours(e.target.value)}
                  variant="standard"
                  value={operatingHours}
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <TextField
                  id="voidPassword"
                  className={classes.field}
                  label="VoidPassword"
                  onChange={(e) => setVoidPassword(e.target.value)}
                  variant="standard"
                  value={voidPassword}
                />
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <TextField
                  id="isOnlineStore"
                  className={classes.field}
                  label="Is Online Store"
                  onChange={(e) => setIsOnlineStore(e.target.value)}
                  variant="standard"
                  value={isOnlineStore}
                />
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <TextField
                  id="settlementEmails"
                  className={classes.field}
                  label="SettlementEmails"
                  onChange={(e) => setSettlementEmails(e.target.value)}
                  variant="standard"
                  value={settlementEmails}
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <TextField
                  id="bankAccount"
                  className={classes.field}
                  label="bankAccount"
                  variant="standard"
                  value={bankAccount}
                />
              </Paper>
            </Grid>
          </Grid>
          

          
          <Grid container spacing={3}>
          <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <BankDetailIdSelect
                  setBankDetailIdChange={setBankDetailIdChange}
                  bank={bank}
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <BusinessIdSelect
                  setBusinessIdChange={setBusinessIdChange}
                  business={business}
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
           

                <StoreIdSelect
                setStoreIdChange={setStoreIdChange}
                store={referralStoreName}
                clearSelectStoreId={clearStoreId} 
                storeLabelText ="Referral Store" 
                />
            </Grid>
            

            
            
          </Grid>

         
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <TextField
                  id="bankName"
                  className={classes.field}
                  label="bankName"
                  variant="standard"
                  value={bankName}
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
             

                <TextField
                  id="address"
                  className={classes.field}
                  label="Address"
                  onChange={(e) => setAddress(e.target.value)}
                  variant="standard"
                  value={address}
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
             

                <TextField
                  id="storeMcc"
                  className={classes.field}
                  label="MCC"
                  onChange={(e) => setStoreMcc(e.target.value)}
                  variant="standard"
                  value={storeMcc}
                />
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <TextField
                  id="registerStatus"
                  className={classes.field}
                  label="RegisterStatus"
                  variant="standard"
                  value={registerStatus}
                  onChange={(e) => setRegisterStatus(e.target.value)}
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
             

                <TextField
                  id="storeRemark"
                  className={classes.field}
                  label="StoreRemark"
                  onChange={(e) => setStoreRemark(e.target.value)}
                  variant="standard"
                  value={storeRemark}
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
              <FormControl fullWidth>
                  <InputLabel id="settlementBankFileShowNameFlag">BankFileShowNameFlag</InputLabel>
                  <Select
                    labelId="settlementBankFileShowNameFlag"
                    id="settlementBankFileShowNameFlag"
                    value={settlementBankFileShowNameFlag}
                    label="BankFileShowNameFlag"
                    onChange={(e) => setSettlementBankFileShowNameFlag(e.target.value)}
                  >
                    <MenuItem value='YES'>YES</MenuItem>
                    <MenuItem value='NO'>NO</MenuItem>
                    
                  </Select>
                </FormControl>

               
              </Paper>
            </Grid>
          </Grid>




          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>

              <FormControl fullWidth>
                  <InputLabel id="refundFifferentDayFlag">RefundFifferentDayFlag</InputLabel>
                  <Select
                    labelId="refundFifferentDayFlag"
                    id="refundFifferentDayFlag"
                    value={refundDifferentDayFlag}
                    label="BankFileShowNameFlag"
                    onChange={(e) => setRefundDifferentDayFlag(e.target.value)}
                  >
                    <MenuItem value='YES'>YES</MenuItem>
                    <MenuItem value='NO'>NO</MenuItem>
                    
                  </Select>
                </FormControl>
                
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
             

                <TextField
                  id="belongFlag"
                  className={classes.field}
                  label="FrasersMall"
                  onChange={(e) => setBelongFlag(e.target.value)}
                  variant="standard"
                  value={belongFlag}
                />
              </Paper>
            </Grid>
            
          </Grid>

          <StoreFormBottonControl>
            <Button type="submit" variant="outlined"  color="primary" className="btn right"  >
              Save
            </Button>
            <Button
            color="primary"
              variant="outlined"
              onClick={handleEditClose}
              className="btn right"
            >
              close
            </Button>
          </StoreFormBottonControl>
        </form>
      </Container>
    </div>
  );
}
