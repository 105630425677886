import request from "./ConfigRequest";

export const pageTransactionDto = async (transactionQueryVo) => {
  return request({
    url: "transaction/pageTransactionDto",
    params: {
      index:transactionQueryVo.index,
      pageSize:transactionQueryVo.pageSize,
      id:transactionQueryVo.id ,
      storeId:transactionQueryVo.storeId,
      businessId:transactionQueryVo.businessId  ,
      acquirerTransactionId:transactionQueryVo.acquirerTransactionId  ,
      tradeNumber:transactionQueryVo.tradeNumber ,
      paymentStatus:transactionQueryVo.paymentStatus ,
      creationTimeStart:transactionQueryVo.creationTimeStart  ,
      creationTimeEnd:transactionQueryVo.creationTimeEnd,
      capturedTimeStart:transactionQueryVo.capturedTimeStart  ,
      capturedTimeEnd:transactionQueryVo.capturedTimeEnd,
      externalTransactionId:transactionQueryVo.externalTransactionId ,
      clientTransactionId:transactionQueryVo.clientTransactionId  ,
      paymentLinkTransactionId:transactionQueryVo.paymentLinkTransactionId,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const updateTransactionDto = (transactionDto) => {
  return request({
    url: "transaction/updateTransactionDto",
    method: "put",
    data: transactionDto,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const searchTransactionDto = (transactionQueryVo) => {
  return request({
    url: "transaction/pageTransactionDto",
    params:transactionQueryVo,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};


export const selectTransactionDtoExport = async (transactionQueryVo) => {
  return request({
    url: "transaction/selectTransactionDtoExport",
    params: {
      index:transactionQueryVo.index,
      pageSize:transactionQueryVo.pageSize,
      id:transactionQueryVo.id ,
      storeId:transactionQueryVo.storeId,
      businessId:transactionQueryVo.businessId  ,
      acquirerTransactionId:transactionQueryVo.acquirerTransactionId  ,
      tradeNumber:transactionQueryVo.tradeNumber ,
      paymentStatus:transactionQueryVo.paymentStatus ,
      creationTimeStart:transactionQueryVo.creationTimeStart  ,
      creationTimeEnd:transactionQueryVo.creationTimeEnd,
      capturedTimeStart:transactionQueryVo.capturedTimeStart  ,
      capturedTimeEnd:transactionQueryVo.capturedTimeEnd,
      externalTransactionId:transactionQueryVo.externalTransactionId ,
      clientTransactionId:transactionQueryVo.clientTransactionId,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    'responseType': 'blob'
  });
};


export const exportPreciseTransactionXlsDtoExport = async (transactionQueryVo) => {
  return request({
    url: "transaction/selectPreciseTransactionDtoExport",
    params: {
      index:transactionQueryVo.index,
      pageSize:transactionQueryVo.pageSize,
      id:transactionQueryVo.id ,
      storeId:transactionQueryVo.storeId,
      businessId:transactionQueryVo.businessId  ,
      acquirerTransactionId:transactionQueryVo.acquirerTransactionId  ,
      tradeNumber:transactionQueryVo.tradeNumber ,
      paymentStatus:transactionQueryVo.paymentStatus ,
      creationTimeStart:transactionQueryVo.creationTimeStart  ,
      creationTimeEnd:transactionQueryVo.creationTimeEnd,
      capturedTimeStart:transactionQueryVo.capturedTimeStart  ,
      capturedTimeEnd:transactionQueryVo.capturedTimeEnd,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    'responseType': 'blob'
  });
};


export const transactionRefund = (transactionQueryVo) => {
  return request({
    url: "transaction/transactionRefund",
    method: "put",
    data: transactionQueryVo,
    headers: {
      "Content-Type": "application/json",
    },
  });
};


export const selectTransactionDtoExportManage = async (transactionQueryVo) => {
  return request({
    url: "transaction/selectTransactionDtoExportManage",
    params: {
      index:transactionQueryVo.index,
      pageSize:transactionQueryVo.pageSize,
      id:transactionQueryVo.id ,
      storeId:transactionQueryVo.storeId,
      businessId:transactionQueryVo.businessId  ,
      acquirerTransactionId:transactionQueryVo.acquirerTransactionId  ,
      tradeNumber:transactionQueryVo.tradeNumber ,
      paymentStatus:transactionQueryVo.paymentStatus ,
      creationTimeStart:transactionQueryVo.creationTimeStart  ,
      creationTimeEnd:transactionQueryVo.creationTimeEnd,
      capturedTimeStart:transactionQueryVo.capturedTimeStart  ,
      capturedTimeEnd:transactionQueryVo.capturedTimeEnd,
      externalTransactionId:transactionQueryVo.externalTransactionId ,
      clientTransactionId:transactionQueryVo.clientTransactionId,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    'responseType': 'blob'
  });
};
